* {
  margin: 0;
  padding: 0;
  font-family: "Outfit", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* #page-wrap {
  overflow-x: hidden;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
}
ul {
  list-style: none;
}
button {
  border: none;
  outline: none;
  cursor: pointer;
}

/* colors */

:root {
  --blue: #102443;
  --pink: #bc2376;
}
.App {
  overflow-x: hidden;
}

/* background */
.bg-gradient {
  background-image: linear-gradient(
    to bottom,
    var(--blue) 70%,
    var(--pink) 30%
  );
}

/*  font */
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@300;500&family=Poppins&display=swap");
