#home {
  background-color: var(--red);
  max-height: 93vh;
  display: flex;
  justify-content: center;
  text-align: center;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  display: none;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
