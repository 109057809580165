.user-profile-card {
  background-color: white;
  min-width: 50vw;
  height: 79vh;
  display: flex;
  align-items: center;
  padding: 20px;
  z-index: 1;
  border-radius: 8px;
  flex-direction: column;
  margin-top: 40px;
}

.upper-user-profile {
  display: flex;
  width: 100%;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  background-color: var(--pink);
  border-radius: 8px;
  color: white;
  padding: 10px;
  transition: 0.3s ease-in-out;
  /* margin-bottom: 20px; */
}

.upper-user-profile:hover {
  transform: translateY(-20px) scale(1.02, 1.02);
  border-radius: 8px 8px 0px 0px;
}

.user-profile-pic {
  /* position: absolute; */
  height: 200px;
  width: 200px;
  padding: 15px;
  /* padding: 0px 15px 0px 0px; */
  /* border-radius: 50%; */
  /* overflow: hidden; */
  /* top: 30px; */
}

.user-profile-pic > img {
  height: 100%;
  width: 100%;
  border-radius: 8px;
  /* object-fit: cover;
  overflow: hidden; */
}

.user-socials,
.user-socials a {
  display: flex;
  align-items: center;
}

.user-socials img {
  margin-left: 9px;
  transition: 0.2s ease-in-out;
}

.user-socials img:hover {
  /* filter: drop-shadow(0.5px 0.5px 3px white); */
  transform: scale(1.2);
}

.user-profile-stats {
  /* padding: 10px; */
  /* margin-top: 5%; */
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: space-around;
  /* width: 100%; */
}

.user-profile-stats a {
  color: white;
}

.user-profile-stats p {
  margin: 0;
  display: inline;
  padding-bottom: 10px;
  padding-top: 15px;
}
.questions-stats {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.user-profile-questions {
  margin-top: 10px;
  width: 100%;
  overflow: auto;
}

@media (min-width: 300px) and (max-width: 820px) {
  .upper-user-profile {
    flex-direction: column;
    text-align: center;
    align-items: center;
  }
  .user-profile-card {
    width: 80vw;
    margin-top: 10px;
    min-height: 130vh;
    margin-bottom: 10vh;
  }

  .user-profile-stats > h1 {
    font-size: 30px;
  }
  .user-profile-pic {
    height: 150px;
    width: 150px;
    top: 70px;
  }
  .questions-stats {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .questions-stats p {
    padding: 0;
  }
  .upper-user-profile:hover {
    transform: translateY(-12px) scale(1.05, 1.05);
  }
}
