.contribute-page {
  /* background-color: var(--blue); */

  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  padding: 2vw;
  padding-bottom: 10vh;
}

.all-questions,
.search-ques {
  width: 70%;
  padding: 20px;
  background-color: white;
  height: 84vh;
  border-radius: 8px;
  overflow: auto;
  margin-right: 30px;
  z-index: 1;
}

.loader {
  margin-top: 20%;
}

.top-contributors {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  max-width: 44vh;
  overflow: auto;
  margin-bottom: 20px;
  /* min-width: 20vw; */
  text-align: center;
}

.top-contributors h2 {
  margin-top: 10px;
}
.contributor-list {
  margin-top: 20px;
}

.top-contributors-list {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid black;
  margin-top: 10px;
}

.top-contributors-list th {
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 5px;
  text-align: left;
  background: #555;
  color: white;
}
.top-contributors-list td,
.top-contributors-list th {
  border: 1px solid black;
  padding: 4px;
  max-width: 500px;
  overflow-wrap: break-word;
}

.contributor-right-col {
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.question-post,
.question-form {
  padding: 1.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
}
.question-form {
  width: 90%;
}

.question-form input,
select {
  margin-bottom: 15px;
  width: 80%;
  padding: 5px;
  border: none;
  background-color: rgb(228, 228, 228);
  border-radius: 5px;
}
.question-form select option {
  padding: 15px;
}

.question-form label {
  margin-bottom: 10px;
}

.question-post-btn {
  background-color: var(--pink);
  padding: 10px;
  border-radius: 8px;
  width: 20%;
  /* margin: auto; */
  color: white;
  transition: all 0.2s ease;
}
.question-post-btn:hover {
  transform: scale(1.1);
  box-shadow: 0px 2px 5px #777;
}

.searchbar {
  width: 50%;
  display: flex;
  align-items: center;
  background-color: rgb(224, 224, 224);
  justify-content: center;
  border-radius: 15px;
  padding: 0px 12px;
  margin-top: 0;
  margin-right: 20px;
}

#searchbar {
  background-color: rgb(224, 224, 224);
  font-size: 17px;
}

.search-ques {
  width: 43%;
  display: flex;
  flex-direction: column;
}
.postq-searchbar {
  width: 96%;
  margin-right: 0;
}

@media (min-width: 821px) and (max-width: 1280px) {
  .contribute-page {
    font-size: 13px;
  }
}
@media (min-width: 300px) and (max-width: 820px) {
  .all-questions,
  .search-ques {
    width: 80%;
    overflow-x: auto;
    margin: 0;
    margin-top: 5vh;
  }
  .contribute-page {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow-x: hidden;
  }

  .allusers {
    font-size: 8px;
  }

  .contribution-head {
    width: 250%;
  }

  .contribution-head > h1 {
    font-size: 5vw;
  }
  .contributor-right-col {
    margin-top: 10vh;
  }
  .top-contributors {
    width: 70%;
  }
}
