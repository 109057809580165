.profile-div {
  min-height: 102vh;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  flex-direction: row;
  background-color: var(--blue);
  padding: 0vw 5vw 0vw;
  /* padding-bottom: 10vh; */
}

.p-card {
  background-color: white;
  /* height: 100%; */
  margin-top: 5vh;
  /* width: 50vw; */
  border-radius: 10px;
  position: relative;
  display: flex;
  align-items: c;
  justify-content: center;
  min-height: 15vh;
  z-index: 1;
  padding: 10px;
}

.p-info {
  background-color: white;
  width: 350px;
  overflow: auto;
  border-radius: 8px;
}

.profile-content {
  background-color: white;
  margin-top: 5vh;
  height: 80vh;
  width: 70%;
  border-radius: 8px;
  overflow: hidden;
  overflow-y: auto;
  z-index: 1;
}

.p-user-details label {
  display: block;
  margin-top: 5px;
}
.p-user-details input {
  width: 95%;
  height: 40px;
  margin: 5px 0;
  border: none;
  outline: none;
  background: #fee;
  padding: 0 8px;
  border-radius: 8px;
}
.p-user-details button {
  background: var(--pink);
  color: white;
  padding: 10px 25px;
  text-transform: uppercase;
  letter-spacing: 1.4px;
  margin: 10px 0;
  border-radius: 8px;
  transition: all 0.2s ease;
  width: 100%;
}

.p-btns {
  padding: 10px;
  display: flex;
  flex-direction: column;
  margin-right: 30px;
}

.p-user-details button:hover {
  transform: scale(1.1);
  box-shadow: 0px 2px 5px #777;
}

.p-contributions {
  padding: 10px;
  width: 97%;
  overflow: auto;
}
.contribution-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-content: center;
  /* margin-bottom: 10px; */
  padding: 10px;
}
.contribution-head-left {
  display: flex;
  align-items: center;
}
.contribution-head-left img {
  cursor: pointer;
  border-radius: 50%;
  margin-right: 10px;
  width: 40px;
}
.contribution-head-left img:hover {
  background-color: rgb(228, 228, 228);
}

/* #refresh {
  margin-left: 20px;
} */

.contribution-head-left > h2 {
  font-family: "Outfit", sans-serif;

  display: inline-block;
}

.p-user-details {
  margin-top: 10px;
  padding: 0px 5px;
}
.role {
  text-align: center;
}
#msg {
  display: flex;
  justify-content: center;
}

.p-card a {
  color: black;
  background-color: white;
  padding: 10px 25px;
  text-transform: uppercase;
  letter-spacing: 1.4px;
  margin: 10px 0;
  border-radius: 19px 8px 8px 19px;
  transition: all 0.2s ease;
  width: 80%;
}

.p-btns {
  display: flex;
  flex-direction: column;
}

.p-btns > .active {
  background: var(--pink);
  width: 90%;
  color: white;
  box-shadow: 0px 2px 5px #777;
}

.p-card a:hover {
  /* transform: scale(1.1); */
  background: var(--pink);
  width: 90%;
  color: white;
  box-shadow: 0px 2px 5px #777;
}

/* table */
.allusers {
  table-layout: auto;
  border-collapse: collapse;
  width: 100%;
  font-size: 14px;
}
.allusers td,
.allusers th {
  border: 1px solid #ddd;
  padding: 8px;
  max-width: 500px;
  overflow-wrap: break-word;
}

.allusers tr {
  font-weight: 100;
}
.allusers th {
  font-weight: 400;
}

#status-head {
  width: 80px;
}
.allusers tr:nth-child(even) {
  background: #f2f2f2;
}
.allusers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background: #555;
  color: white;
}
.allusers i {
  cursor: pointer;
}
.allusers a {
  text-decoration: none;
  color: black;
}
#actions {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.action-icon {
  color: black;
}

#right-col {
  width: auto;
  height: 50%;
}
#p-user-details {
  margin-top: 2vh;
}
#p-info {
  border-radius: 8px;
  /* height: 50%; */
}

#checkboxes {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.checkbox {
  display: flex;
  align-items: center;
}

#isAdmin {
  width: 20px;
  height: 20px;
  margin: 10px 0px;
}

#isAdmin + label {
  display: inline-block;
  margin-left: 5px;
}

.is-evaluating {
  border: 2px solid black;
}

.e-container {
  width: 60vw;
}

.evaluating-question-main {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.evaluating-question-main > p {
  font-size: 18px;
  margin-bottom: 20px;
}
.evaluation-heading {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 10px;
  margin-bottom: 30px;
}

.evaluation-heading > h3 {
  font-size: 16px;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.evaluator-actions {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 40px;
}
.e-btns {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
  width: 70%;
}
.e-btns > a {
  width: 40%;
}
.evaluator-actions button {
  width: 40%;
}
.evaluator-actions button:hover {
  transform: scale(1.2);
  box-shadow: 0px 2px 5px #777;
}

.evaluator-actions > textarea {
  font-size: 17px;
  margin-top: 20px;
  padding: 5px;
}

.dashboard-welcome {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  height: 80%;
  padding: 10px;
}
.dashboard-welcome > h1 {
  margin-bottom: 40px;
}

.dashboard-welcome li {
  padding: 10px;
}

@media (min-width: 300px) and (max-width: 820px) {
  .profile-div {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 10vh;
  }
  .p-card {
    width: 75%;
  }
  .profile-content {
    height: 80vh;
    width: 95%;
  }
  .p-contributions {
    height: 80vh;
    overflow: auto;
  }
  .contribution-head > h3 {
    font-size: 3vw;
  }
  .contribution-head-left > h2 {
    font-size: 12px;
  }
  .e-container {
    width: 90vw;
    min-height: 79vh;
  }
  .e-btns {
    width: 100%;
  }
  .evaluating-question-main > p {
    font-size: 12px;
  }
  .evaluation-heading > h3 {
    font-size: 10px;
  }
  .evaluator-actions > textarea {
    font-size: 12px;
    margin-top: 20px;
    padding: 5px;
  }
}
