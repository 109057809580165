.home-div {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  background-color: var(--blue);
  margin-top: -50px;
}
.head-content {
  margin-right: 25%;
  margin-top: -10vh;
}
.heading-home {
  display: flex;
  align-items: center;
}

#home-logo {
  height: 19vw;
  position: relative;
  margin-bottom: auto;
}

.home-div p {
  font-size: 1.5vw;
  color: white;
  font-family: "Outfit", sans-serif;
}
.heading-home h1 {
  font-size: 7vw;
  color: white;
  font-family: "Outfit", sans-serif;
  align-self: flex-end;
}
.home-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 70%;
  /* margin: auto; */
  margin-top: 3vh;
  flex-wrap: wrap;
}
.home-btn button {
  margin: 10px;
  font-size: 1rem;
  min-width: 130px;
  padding: 10px 25px;
  color: white;
  border: none;
  border-radius: 7px;
  background-color: var(--pink);
  transition: all 0.2s ease;
  font-weight: 500;
  font-family: "Outfit", sans-serif;
}

.home-btn button:hover {
  transform: scale(1.1);
  box-shadow: 0px 2px 5px #777;
}
.home-band {
  height: 110vh;
  background-color: var(--pink);
  width: 10%;
  position: relative;
  top: -49px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#home-gif {
  position: absolute;
  width: 27vw;
}

.mid-home {
  /* min-height: 100vh; */
  text-align: center;
}
.mid-home > h1 {
  font-size: 4vw;
  padding-top: 7vh;
}
.mid-home-content {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.mid-home-text {
  font-size: 1.8vw;
  text-align: left;
}
.mid-home-text > ul {
  list-style: circle;
}
.a > li {
  padding: 10px;
}
.mid-home-image > img {
  width: 45vw;
  margin-bottom: 10vh;
}

.counter-div {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: var(--pink);
}

.counter-div > p {
  color: white;
  font-size: 2vw;
  padding: 10px;
  font-weight: 400;
}

.donate-page {
  height: 100vh;
  background-color: var(--blue);
  display: flex;
  /* align-items: center; */
  justify-content: center;
}
.donate-div {
  padding: 40px;
  height: 60vh;
  width: 30vw;
  background-color: white;
  display: flex;
  flex-direction: column;
  text-align: center;
  border-radius: 8px;
  z-index: 1;
  margin-top: 10vh;
  /* justify-content: center; */
}
.donate-div img {
  object-fit: contain;
  width: 70%;
  margin: auto;
}

#donate-btn {
  background-color: var(--pink);
  color: white;
  padding: 8px 10px;
  width: 50%;
  margin: auto;
  border-radius: 5px;
  transition: all 0.2s ease;
}
#donate-btn:hover {
  transform: scale(1.1);
  box-shadow: 0px 2px 5px #777;
}

#mobile-gif {
  display: none;
}
@media (min-width: 300px) and (max-width: 820px) {
  .home-div {
    height: 100vh;
    flex-direction: column;
    padding-top: 5%;
  }
  .home-band {
    display: none;
  }
  .head-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    width: 100%;
  }
  .home-btn {
    width: 80%;
  }
  .home-btn button {
    margin: 10px;
    font-size: 0.7rem;
    min-width: 110px;
    padding: 8px 12.5px;
    color: white;
    border: none;
    border-radius: 7px;
    background-color: var(--pink);
    transition: all 0.2s ease;
    font-weight: 500;
    font-family: "Outfit", sans-serif;
  }
  #mobile-gif {
    display: block;
    width: 100vw;
  }

  .heading-home > img {
    height: 35vw;
  }
  .home-div p {
    font-size: 2.6vw;
    color: white;
    font-family: "Outfit", sans-serif;
  }
  .heading-home h1 {
    font-size: 18vw;
    color: white;
    font-family: "Outfit", sans-serif;
    align-self: flex-end;
  }

  .mid-home-content {
    flex-direction: column;
  }
  .mid-home > h1 {
    font-size: 10vw;
    margin-bottom: 5vh;
  }
  .mid-home-text {
    font-size: 3.8vw;
    text-align: left;
  }

  .mid-home-image > img {
    width: 85vw;
    margin-bottom: 10vh;
  }
  .donate-div {
    width: 70vw;
  }
  .counter-div {
    background-color: var(--blue);
  }
  @keyframes move {
    0% {
      transform: translate(-100px) rotate(0deg);
    }
    50% {
      transform: translate(-50px) rotate(180deg);
    }
    100% {
      transform: translate(0px) rotate(360deg);
    }
  }
}
