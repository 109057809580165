.navbar {
  background-color: var(--blue);
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  /* position: relative; */
}

.hamburger-react {
  color: white;
  margin-left: 10px;
  z-index: 3;
}

header {
  height: 100%;
  background-color: var(--pink);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 250px;
  position: fixed;
  top: 0;
  left: 0;
  transform: translateX(-100%);
  transition: transform 0.45s;
  z-index: 2;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

header > a {
  padding: 8px 15px;
  text-decoration: none;
  color: white;
  margin-top: 10px;
  width: 80%;
  text-align: center;
}

header > h1 {
  color: white;
  margin-top: auto;
}

header > a:nth-child(1) {
  margin-top: 70px;
}

header > a:hover {
  background-color: var(--blue);
  border-radius: 10px;
}

.nav-open {
  transform: translateX(0%);
}

#logo {
  height: 50px;
  /* height: fit-content; */
}

.active {
  background-color: var(--blue);
  color: white;
  border-radius: 10px;
}
.avatar-icon {
  height: 30px;
  width: 30px;
  transform: translateY(10px);
  margin-right: 8px;
  border-radius: 50%;
}

.drop-nav {
  position: relative;
  /* padding: 10px 0px; */
}
.avatar {
  padding: 8px 20px;
}
.navbar > li {
  list-style: none;
}

#login-register {
  color: white;
  margin-right: 10px;
  padding: 8px 20px;
  border-radius: 8px;
  margin-top: 9px;
}
#login-register:hover {
  background-color: var(--pink);
}

.drop-nav > a {
  background-color: var(--pink);
  margin: 6px;
  color: white;
  border-radius: 8px;
  padding: 14px;
}

.dropdown > a {
  text-transform: capitalize;
  overflow: hidden;
  margin: 15px 0px;
  cursor: pointer;
  color: white;
}

.dropdown > a:hover {
  color: var(--blue);
}

.fa {
  margin-left: 5px;
}

.dropdown {
  position: absolute;
  background: var(--pink);
  width: 93.5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 41px;
  left: 6px;
  border-radius: 0px 0px 6px 6px;
  z-index: 2;
  transition: all 0.4s ease;
}

#down-arrow {
  transform: rotateX(180deg);
}

@media (min-width: 300px) and (max-width: 820px) {
}
