.api-page {
  width: 45%;
  margin-right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}

.api-container {
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.api-form {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  margin: 0;
  width: 100%;
}
.api-form input,
select {
  padding: 5px 10px;
  width: 40%;
  margin-left: 10px;
  /* margin-top: 40px; */
  background-color: rgb(228, 228, 228);
  border: none;
}
.api-form label {
  margin-top: 20px;
}

#url {
  width: 100%;
  outline: none;
  border: none;
  padding: 7px 0px;
}
#url-btn {
  background-color: var(--pink);
  padding: 10px;
  color: white;
  border-radius: 8px;
  margin-top: 30px;
}

#url-input {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70%;
  outline: 1px solid black;
  padding: 0px 9px;
  border-radius: 5px;
}

#copy-btn {
  background-color: white;
}

@media (min-width: 821px) and (max-width: 1280px) {
  .api-page {
    width: 55%;
    overflow-y: auto;
  }
  .api-form input,
  select {
    width: 70%;
  }
}

@media (min-width: 300px) and (max-width: 820px) {
  .api-page {
    width: 80%;
    overflow-y: auto;
  }
  .api-form input,
  select {
    width: 70%;
  }
}
