.login-div {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: var(--blue);
}

.login-page {
  background-color: white;
  /* max-width: 55%; */
  padding: 20px;
  border-radius: 10px;
  margin: auto;
  display: flex;
  flex-direction: row;
  padding: 20px;
  margin-top: 5vh;
  margin-bottom: 15vh;
  height: 65vh;
  z-index: 1;
  box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.5);
}

.login-page img {
  max-width: 25vw;
  max-height: 50vh;
  margin: 10%;
}

.logo-div {
  margin-right: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-page label {
  display: block;
  font-family: "Outfit", sans-serif;
}
.login-section {
  width: 20vw;
  padding: 30px;
  border-left: 2px solid grey;
  overflow: auto;
}
.login-section h1 {
  font-size: 2rem;
  font-family: "Outfit", sans-serif;
  margin-bottom: 20px;
}

.login-section input {
  width: 100%;
  height: 21px;
  border: none;
  /* border: 1px solid black; */
  padding: 5px;
  outline: none;
}

.input-div {
  width: 95%;
  height: 25px;
  margin: 6px 0px;
  border: 1.9px solid rgb(196, 196, 196);
  border-radius: 8px;
  padding: 3px;
  display: flex;
  align-items: center;
}

.login-section input::placeholder {
  font-family: "Outfit", sans-serif;
  font-weight: 100;
}

#pass-eye {
  margin: 0;
  cursor: pointer;
}

.login-section button {
  background-color: var(--pink);
  padding: 8px 20px;
  margin-top: 10px;
  color: white;
  font-family: "Outfit", sans-serif;
  border-radius: 4px;
  min-width: 100px;
  transition: all 0.2s ease;
}

.login-section button:hover {
  transform: scale(1.1);
  box-shadow: 0px 2px 5px #777;
}

/* .hr {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10%;
}
.hr > hr {
  width: 35%;
} */
.row {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap-reverse;
  align-items: center;
}
.row a {
  margin-top: 10px;
  color: crimson;
}

.login-section p {
  font-family: "Outfit", sans-serif;
  margin: 10px 0;
  font-weight: 100;
}
#switchlink {
  font-family: "Outfit", sans-serif;
  color: #111;
  font-weight: 500;
}

.notfound {
  height: 100vh;
  display: flex;
  justify-content: center;
  background-color: var(--blue);
}
.notfound {
  height: 100vh;
  display: flex;
  justify-content: center;
  background-color: var(--blue);
}
.notfound > h1 {
  color: white;
}

@media (min-width: 300px) and (max-width: 820px) {
  .login-page {
    width: 80%;
  }
  .logo-div {
    display: none;
  }
  .login-section {
    width: 90%;
  }
}
