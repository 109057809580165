.footer {
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: center; */
  /* justify-content: space-between; */
  padding: 10px;
  padding-top: 30px;
  overflow-x: hidden;
  color: white;
  background-color: var(--blue);
  overflow-x: hidden;
  font-family: "Bai Jamjuree", sans-serif;
}
hr {
  color: rgb(221, 221, 221);
  opacity: 0.4;
  width: 100%;
  margin-top: 20px;
}

.footer-content {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.footer-links > a > p {
  color: white;
  text-decoration: none;
  font-weight: 500;
  padding: 5px;
}

.footer-links > a > p:hover {
  color: var(--pink);
}

.footer-links {
  padding: 15px;
}

footer > p {
  padding: 5px;
  margin: 0px;
}

.footer-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 2px solid black; */
  border-radius: 50%;
}

.footer-logo > img {
  height: 7rem;
}

.copyright {
  padding: 5px;
  text-align: center;
}

@media (min-width: 300px) and (max-width: 820px) {
  .footer-content {
    flex-direction: column;
  }
  .footer-links {
    display: flex;
    flex-direction: row;
  }
  .footer-links > a {
    font-size: 0.7rem;
    text-align: center;
  }

  .copyright {
    font-size: 0.8rem;
  }
  .footer-logo > img {
    height: 5rem;
  }
  .footer-links > a > p:hover {
    color: var(--blue);
  }
}
